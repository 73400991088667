import { CustomerType } from "./CustomerType.js";

/**
 * Additional hours when orders for next day are still possible but with surcharge for late order.
 */
export const orderCutoffHourTolerance: Record<CustomerType, number> = {
	[CustomerType.B2c]: 0,
	[CustomerType.B2b]: +1,
};

/**
 * Surcharge for late order in CZK.
 */
export const lateOrderSurcharge: Record<CustomerType, number> = {
	[CustomerType.B2c]: 0,
	[CustomerType.B2b]: 300,
};
