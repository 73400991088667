import { CustomerType } from "./CustomerType.js";

/*
 * Minimal time at which an order can be placed
 */
export const minOrderHour = 10;
export const minOrderMinute = 0;

/*
 * Maximal time at which an order can be placed
 */
export const maxOrderHour = 17;
export const maxOrderMinute = 30;

/*
 * Time after which an order cannot be placed at the minimal time at the day of production.
 */
export const orderCutoffHours: Record<CustomerType, number> = {
	[CustomerType.B2c]: 7,
	[CustomerType.B2b]: 13,
};

/*
 * Minutes by which to round up order times.
 */
export const orderTimeStepInSeconds = 15 * 60;
